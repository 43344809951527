import React from 'react';
import PropTypes from 'prop-types';
import colors from '../config/color';
import { FaLine, FaFacebookSquare, FaCaretSquareUp } from 'react-icons/fa';

const GoTop = props => {
  const { pageUrl } = props;
  const thisPageUrl = encodeURIComponent(`${pageUrl}`);
  return (
    <div className="action-contnet">
      <div
        className="icon top-icon"
        onClick={() => {
          const scrollToTop = () => {
            const c =
              document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
              window.requestAnimationFrame(scrollToTop);
              window.scrollTo(0, c - c / 8);
            }
          };
          scrollToTop();
        }}
      >
        <FaCaretSquareUp style={{ height: 40, width: 40 }} />
      </div>
      <div
        className="icon line-icon"
        onClick={() => {
          window.open(
            `https://social-plugins.line.me/lineit/share?url=${thisPageUrl}`,
          );
        }}
      >
        <FaLine style={{ height: 40, width: 40 }} />
      </div>
      <div
        className="icon fb-icon"
        onClick={() => {
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${thisPageUrl}`,
          );
        }}
      >
        <FaFacebookSquare style={{ height: 40, width: 40 }} />
      </div>

      <style jsx>{`
        .action-contnet {
          color: ${colors['dirt-brown']};
          display: flex;
          flex-direction: column;
        }

        .icon:hover {
          color: ${colors['dirt-brown-70%']};
        }
      `}</style>
    </div>
  );
};

GoTop.propTypes = {
  pageUrl: PropTypes.string,
};

GoTop.defaultProps = {
  pageUrl: '',
};

export default GoTop;
