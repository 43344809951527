import React, { Component } from 'react';
import { graphql } from 'gatsby';
import 'lazysizes';
import PropTypes from 'prop-types';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Layout from '../components/layout';
import SEO from '../components/seo';
import './post.css';
import colors from '../config/color';
import Tag from '../components/tag';
import GoTop from '../components/goTop';
import Product from '../components/product';
import Category from '../components/category';

class Post extends Component {
  constructor() {
    super();
    this.scrollPercentRounded = 0;
    this.showAd = true;
  }

  componentWillMount() {}

  componentDidMount() {
    const {
      pageContext: { isNoAd },
    } = this.props;
    this.awesomeBtnAnimate();
    this.detectScroll();
    this.detectScrollTitle();
    try {
      // const adBlockNotDetected = () => {
      //   // alert('AdBlock is not enabled');
      // };
      // // Function called if AdBlock is detected
      // const adBlockDetected = () => {
      //   this.showAd = false;
      //   console.log('AdBlock is enabled');
      // };

      // // We look at whether FuckAdBlock already exists.
      // if (
      //   typeof fuckAdBlock !== 'undefined' ||
      //   typeof FuckAdBlock !== 'undefined'
      // ) {
      //   // If this is the case, it means that something tries to usurp are identity
      //   // So, considering that it is a detection
      //   adBlockDetected();
      // } else {
      //   // Otherwise, you import the script FuckAdBlock
      //   var importFAB = document.createElement('script');
      //   importFAB.onload = function() {
      //     // If all goes well, we configure FuckAdBlock
      //     fuckAdBlock.onDetected(adBlockDetected);
      //     fuckAdBlock.onNotDetected(adBlockNotDetected);
      //   };
      //   importFAB.onerror = function() {
      //     // If the script does not load (blocked, integrity error, ...)
      //     // Then a detection is triggered
      //     adBlockDetected();
      //   };
      //   importFAB.integrity =
      //     'sha256-xjwKUY/NgkPjZZBOtOxRYtK20GaqTwUCf7WYCJ1z69w=';
      //   importFAB.crossOrigin = 'anonymous';
      //   importFAB.src =
      //     'https://cdnjs.cloudflare.com/ajax/libs/fuckadblock/3.2.1/fuckadblock.min.js';
      //   document.head.appendChild(importFAB);
      // }

      // console.log('this.showAd', this.showAd);
      // while (this.showAd) {
      //   (adsbygoogle = window.adsbygoogle || []).push({});
      // }
      for (
        let a = 0;
        a < document.getElementsByClassName('adsbygoogle').length;
        a++
      ) {
        (adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch (error) {}
  }

  awesomeBtnAnimate = () => {
    if (document.body.offsetWidth < 576) return;
    const cleanClass = function(e) {
      if (e) {
        e.classList.remove('styles--aws-btn--middle');
        e.classList.remove('styles--aws-btn--left');
        e.classList.remove('styles--aws-btn--right');
        e.classList.remove('styles--aws-btn--active');
      }
    };

    const btn = document.getElementsByClassName('my-aws-btn');

    for (let i = 0; i < btn.length; i++) {
      (function(index) {
        btn[index].addEventListener('click', function(e) {
          trackCustomEvent({
            category: 'Click Affiliates Btn',
            action: decodeURI(window.location.href),
            label: btn[index].text + ' ' + btn[index].href,
          });
        });
      })(i);

      (function(index) {
        btn[index].addEventListener('mouseleave', function(e) {
          cleanClass(btn[index]);
        });
      })(i);

      (function(index) {
        btn[index].addEventListener('mousemove', function(e) {
          let x = e.pageX - btn[index].offsetLeft;
          let y = e.pageY - btn[index].offsetTop;
          // 		console.log(e.pageX, this.offsetLeft, $(this).offset(), x, y, $(this).width());
          let width = btn[index].offsetWidth;
          if (x > 0 && x <= width * 0.333) {
            // console.log('L', y, width * 0.333)
            if (!btn[index].classList.contains('styles--aws-btn--left')) {
              cleanClass(btn[index]);
              btn[index].classList.toggle('styles--aws-btn--left');
            }
          } else if (x > width * 0.333 && x <= width * 0.666) {
            // console.log('C')
            if (!btn[index].classList.contains('styles--aws-btn--middle')) {
              cleanClass(btn[index]);
              btn[index].classList.toggle('styles--aws-btn--middle');
            }
          } else if (x > width * 0.666 && x <= width) {
            // console.log('R')
            if (!btn[index].classList.contains('styles--aws-btn--right')) {
              cleanClass(btn[index]);
              btn[index].classList.toggle('styles--aws-btn--right');
            }
          }
        });
      })(i);

      (function(index) {
        btn[index].addEventListener('mousedown', function(e) {
          cleanClass(btn[index]);
          if (!btn[index].classList.contains('styles--aws-btn--active')) {
            cleanClass(btn[index]);
            btn[index].classList.toggle('styles--aws-btn--active');
          }
          // gtag('event', 'click_buy_button', {
          //   'event_category': decodeURI(window.location.href),
          //   'event_label': btn[index].text() + ' ' + btn[index].attr('href'),
          // });
        });
      })(i);
    }
  };

  detectScroll = () => {
    window.onscroll = function() {
      const scrollTop = document.documentElement.scrollTop;
      const docHeight = document.documentElement.offsetHeight;
      const winHeight = window.innerHeight;
      const scrollPercent = scrollTop / (docHeight - winHeight);
      const scrollPercentRounded = Math.round(scrollPercent * 100);
      if (
        scrollPercentRounded % 10 === 0 &&
        this.scrollPercentRounded !== scrollPercentRounded
      ) {
        this.scrollPercentRounded = scrollPercentRounded;
        // trackCustomEvent({
        //   category: 'Scroll Depth',
        //   action: decodeURI(window.location.href),
        //   label: `${scrollPercentRounded}%`,
        // });
      }
    };
  };

  detectScrollTitle = () => {
    if (!IntersectionObserver) return;
    let targets = [];
    targets = [...targets, ...document.querySelectorAll('article h3')];
    targets = [...targets, ...document.querySelectorAll('article h2')];

    let observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            trackCustomEvent({
              category: 'Scroll Appear Title',
              action: decodeURI(window.location.href),
              label: entry.target.innerHTML,
            });
          }
        });
      },
      {
        root: null,
        rootMargin: '150px',
        threshold: 1.0,
      },
    );

    for (let i = 0; i < targets.length; i++) {
      observer.observe(targets[i]);
    }
  };

  render() {
    const {
      pageContext: { isNoAd, content },
    } = this.props;
    const post = this.props.data.wordpressPost;
    let {
      categories: postCategories,
      modifiedFormat,
      tags,
      wordpress_id,
    } = this.props.data.wordpressPost;
    const relatedPost = this.props.data.allWordpressPost.edges;
    const categories = this.props.data.allWordpressCategory.edges;
    const siteMetadata = this.props.data.site.siteMetadata;

    postCategories = postCategories.filter(o => o.slug !== 'noad');

    const breadcrumbs = [
      {
        data: postCategories.map(category => ({
          name: category.name,
          url: `category/${category.slug}/1/`,
        })),
      },
      {
        data: [
          {
            name: post.title,
            url: `/${decodeURIComponent(post.slug)}/`,
          },
        ],
      },
    ];
    const metaTag = tags.map(o => ({
      property: 'article:tag',
      content: o.name,
    }));
    return (
      <Layout breadcrumbs={breadcrumbs}>
        <SEO
          isNoAd={isNoAd}
          lang="zh-cmn-Hant"
          metaType={'article'}
          metaUrl={`${decodeURIComponent(post.slug)}`}
          title={post.yoast_meta.yoast_wpseo_title}
          description={post.yoast_meta.yoast_wpseo_metadesc}
          image={post.featured_media.source_url}
          canonicalUrl={`${siteMetadata.siteUrl}/${post.slug}/`}
          schema={[
            {
              '@type': 'Article',
              name: post.title,
              headline: post.yoast_meta.yoast_wpseo_metadesc.substring(0, 110),
              url: `${siteMetadata.siteUrl}/${post.slug}/`,
              mainEntityOfPage: `${siteMetadata.siteUrl}/${post.slug}/`,
              image: {
                '@type': 'ImageObject',
                url: post.featured_media.source_url,
                width: post.featured_media.media_details.width,
                height: post.featured_media.media_details.height,
              },
              author: {
                '@type': 'Organization',
                name: 'WoWindow',
              },
              publisher: {
                '@type': 'Organization',
                name: 'WoWindow',
                logo: {
                  '@type': 'ImageObject',
                  url:
                    'https://wowindow.tw/static/logo-58e5ee2835e67a60c56e51f445fb4973.svg',
                  width: 130,
                  height: 25,
                },
              },
              articleSection: post.yoast_meta.yoast_wpseo_metadesc,
              articleBody: post.yoast_meta.yoast_wpseo_metadesc,
              datePublished: post.modifiedFormatZ,
              dateModified: post.modifiedFormatZ,
            },
            {
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: 'WoWindow',
                  item: siteMetadata.siteUrl,
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: postCategories[0].name,
                  item: `${siteMetadata.siteUrl}/category/${postCategories[0].slug}/1/`,
                },
                {
                  '@type': 'ListItem',
                  position: 3,
                  name: post.title,
                  item: `${siteMetadata.siteUrl}/${post.slug}`,
                },
              ],
            },
          ]}
          meta={[
            ...metaTag,
            {
              property: `article:published_time`,
              content: post.date,
            },
            {
              property: `article:modified_time`,
              content: post.modified,
            },
            {
              property: `og:updated_time`,
              content: post.modified,
            },
            {
              property: `og:image:secure_url`,
              content: post.featured_media.source_url,
            },
            {
              property: `og:image:width`,
              content: post.featured_media.media_details.width,
            },
            {
              property: `og:image:height`,
              content: post.featured_media.media_details.height,
            },
            {
              name: `twitter:card`,
              content: `summary_large_image`,
            },
            {
              name: `twitter:description`,
              content: post.yoast_meta.yoast_wpseo_metadesc,
            },
            {
              name: `twitter:title`,
              content: post.yoast_meta.yoast_wpseo_title,
            },
            {
              name: `twitter:image`,
              content: post.featured_media.source_url,
            },
            {
              name: `twitter:image`,
              content: post.featured_media.source_url,
            },
          ]}
        />
        <div className="title">
          <h1 id="post-title">{post.title}</h1>
          <div className="title-bottom">
            <div className="content-tag">
              {postCategories.map(tagProps => (
                <Tag key={tagProps.slug} {...tagProps} />
              ))}
            </div>
            <div className="update">{`更新日期：${modifiedFormat}`}</div>
          </div>
        </div>
        <div className="gotop">
          <GoTop pageUrl={`${siteMetadata.siteUrl}/${wordpress_id}`} />
        </div>
        <article className="post-article-main">
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </article>

        <Product title={'你可能也喜歡'} edges={relatedPost} />
        <Category edges={categories} />
        <style jsx>{`
          .title {
            max-width: 900px;
            margin: auto;
            padding: 20px 20px 0 20px;
          }

          h1 {
            padding: 20px 0 10px 0;
            font-size: 26px;
            line-height: 1.6;
            color: ${colors['dirt-brown']};
          }

          .title-bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .update {
            margin-left: 5px;
            font-size: 14px;
            color: ${colors['dirt-brown']};
          }

          .gotop {
            position: fixed;
            right: 20px;
            bottom: 90px;
            z-index: 98;
          }
        `}</style>
      </Layout>
    );
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
};

Post.defaultProps = {};

export default Post;

export const postQuery = graphql`
  query($id: String!, $categoriesId: [String]!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: {
        id: { ne: $id }
        categories: { elemMatch: { id: { in: $categoriesId } } }
      }
      limit: 20
    ) {
      edges {
        node {
          title
          slug
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
          }
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          featured_media {
            source_url
            title
          }
        }
      }
    }
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      slug
      title
      content
      modified
      modifiedFormat
      date
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      categories {
        wordpress_id
        id
        name
        slug
      }
      tags {
        id
        name
        slug
      }
      featured_media {
        source_url
        title
        media_details {
          height
          width
        }
      }
    }
    allWordpressCategory(filter: { slug: { nin: "noad" } }) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
